import React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

export const BlockOferta: React.FC<BlockOfertaParams> = ({oferta}: BlockOfertaParams) => {

    return (
        <div className={"block-oferta-wrapper"}
             {...{
                 'data-aos': "fade-down",
                 'data-aos-offset': "300",
                 'data-aos-easing': "ease-in-sine",
                 'data-aos-once': "true"
             }}
        >
            <div className={"block-oferta-opaquer"}>
                <div className={"block-oferta-image"}>
                    <GatsbyImage
                        image={getImage(oferta.ImageOnHomepage?.localFile)}
                        alt={oferta.ImageOnHomepage?.alternativeText}
                    />
                </div>
                <div
                    className={"block-oferta-name"}
                >
                    <span className={"title"}>{oferta.OfertaData?.name}</span>
                    <span className={"description"}>{oferta.OfertaData?.description}</span>
                </div>
            </div>
        </div>
    );
}

export type BlockOfertaType = {
    readonly ImageOnHomepage: any;
    readonly OfertaData: {
        name: string;
        description: string;
        images: any[];
    };
    readonly Order: number;
    readonly ShowOnHomepage: boolean;
    readonly strapi_id: number;
}

export interface BlockOfertaParams {
    oferta: BlockOfertaType
}