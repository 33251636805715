import React from 'react';
import { Link } from 'gatsby';
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import DocPlannerIcon from "@assets/svg/doc-planner-icon.inline.svg";

const DoctorCardSlider = ({ employer }) => {

    return (
        <>
            <div className="">
                <div className="team-wrapper team-box-2 team-box-2-no-bg text-center mt-10 mb-10">
                    {/*<Link to={`/about/${employer.strapi_id}/${employer.slug}`}>*/}
                        <div className="team-thumb">
                            <GatsbyImage
                                image={getImage(employer.cover?.localFile)}
                                alt={employer.cover?.alternativeText}
                            />
                        </div>
                    {/*</Link>*/}
                    <div className="team-member-info mt-35 mb-25">
                        <h3><Link to={`/about/${employer.strapi_id}/${employer.slug}`}>{employer.name}</Link></h3>
                        {/*<h6 className="f-500 text-up-case letter-spacing color-employer-text">{employer.title}</h6>*/}
                    </div>
                    <p className={"employer-card-socials mt-0 mb-15"}>
                        {
                            !!employer.nr_telefonu && (
                                <span>
                                                    <Link to={"tel:" + employer.nr_telefonu} target={"_blank"}>
                                                        <i className={"fa fa-phone-alt"} />
                                                    </Link>
                                                </span>
                            )
                        }
                        {
                            !!employer.facebook_url && (
                                <span>
                                                    <Link to={employer.facebook_url} target={"_blank"}>
                                                        <i className={"fab fa-facebook-f"} />
                                                    </Link>
                                                </span>
                            )
                        }
                        {
                            !!employer.instagram_url && (
                                <span>
                                                    <Link to={employer.instagram_url} target={"_blank"}>
                                                        <i className={"fab fa-instagram"} />
                                                    </Link>
                                                </span>
                            )
                        }
                        {
                            !!employer.znanylekarz_url && (
                                <span>
                                                    <Link to={employer.znanylekarz_url} target={"_blank"}>
                                                        <DocPlannerIcon style={{marginTop: "-8px"} }/>
                                                    </Link>
                                                </span>
                            )
                        }
                    </p>
                    <div>
                        { !!employer.znanylekarz_url && (
                            <div className="service-details-text mb-40">
                                <a
                                    className="primary_btn header_primary_btn btn-zl" target={"_blank"} href={employer.znanylekarz_url} style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}>
                                    <DocPlannerIcon class={"path-color-white"}  style={{marginTop: "-4px", width: "24px", marginRight: "10px",} }/>
                                    <span>Umów wizytę</span>
                                    <i className="fa fa-angle-right" style={{marginLeft: "10px"}}></i>
                                </a>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
        </>
    );
};

export default DoctorCardSlider;