import React from 'react';
import {useDispatch} from "react-redux";
import {Collapse} from "react-collapse";

const FaqBlock = ({id, title, description, isActive}) => {

    const dispatch = useDispatch();

    const setActive = () =>
        dispatch({
            type: 'SET_ACTIVE_FAQ',
            activeFaqIndex: isActive ? -1 : id,
        });

    return (
        <>
            <div className={"faq-block-container"} onClick={() => setActive()}>
                <span className={"align-items-center d-flex justify-content-between"}>
                    <p className={"faq-block-title"}>{ title }</p>
                    <span>
                        {
                            !isActive ? (
                                <i className="faq-fa-icon fa-bold fa-plus-circle far"></i>
                            ) : (
                                <i className="faq-fa-icon fa-bold fa-minus-circle far"></i>
                            )
                        }
                    </span>
                </span>
                <Collapse isOpened={isActive}>
                    <div className={"faq-block-description"}>{ description }</div>
                </Collapse>
            </div>
        </>
    );
};

export default FaqBlock;