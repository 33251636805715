import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import HomeThreeSingleBlog from "@components/common/HomeThreeSingleBlog/HomeThreeSingleBlog";
import useGlobalContext from "@hooks/useGlobalContext";
import FaqRows from "@components/faq/faq-rows";
import {useMobileDetect} from "@hooks/useIsMobile";

export const FAQSection: React.FC = () => {

    const {isMobile} = useGlobalContext();

    return <section className="gray-bg pt-115 pb-90 mobile-pt-30">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-7">
                    <div className="section-title pos-rel mb-75">
                        <div className="section-text pos-rel"
                             { ...!isMobile ? {...{
                                     'data-aos': "fade-right",
                                     'data-aos-offset': "300",
                                     'data-aos-easing': "ease-in-sine",
                                     'data-aos-once': "true"
                                 }} : {} }
                        >
                            <h5>FAQ</h5>
                            <h1>Najczęściej zadawane pytania</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <FaqRows />
                </div>
            </div>
        </div>
    </section>
}