import React, {useEffect} from "react"
import {graphql, useStaticQuery} from "gatsby"
import FaqBlock from "@components/faq/faq-block";
import {useSelector} from "react-redux";

const FaqRows = (params) => {

    const activeFaqIndex = useSelector<any, any>(state => state.activeFaqIndex);

    const data = useStaticQuery(graphql`
        query {
            allStrapiFaq {
                edges {
                    node {
                        faq_collection {
                            ...FAQBlock
                        }
                    }
                }
            }
        }
    `);

    return (
        <>
            <div>
                {
                    data.allStrapiFaq.edges[0].node.faq_collection.map(faq => (
                        <FaqBlock
                            title={faq.title}
                            description={faq.description}
                            key={faq.strapi_id}
                            id={faq.strapi_id}
                            isActive={faq.strapi_id === activeFaqIndex}
                        />
                    ))
                }
            </div>
        </>
    )
}

export const query = graphql`
    fragment FAQBlock on STRAPI__COMPONENT_FAQ_FAQ_BLOCK {
        strapi_id
        title
        description
    }
`

export default FaqRows;