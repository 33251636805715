import React from "react";
import {Link} from "gatsby";
import { useParallax } from "react-scroll-parallax";
export const BlockPricingOffer: React.FC = () => {

    const parallax = useParallax({
        speed: 5,
    });

    return (
        <section className="block-pricing-offer pt-115 pb-90">
            <div className={"container"} ref={parallax.ref}>
                <div className={"row"}
                     data-aos="fade-right"
                     data-aos-easing="ease-in-sine"
                     data-aos-once="true"
                >
                    <div className="col-12 section-title text-center" >
                        <h1 className={"text-white"}
                        >Każdy zabieg zawarty jest w cenie wizyty!</h1>
                    </div>
                    <div className={"col-12"}>
                        <div className="section-button text-center pt-40">
                            <Link to="/cennik" className="primary_btn btn-dark btn-icon ml-0"><span>&#8674;</span>zobacz cennik</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}