// Slick ArrowLeft
import React from "react";

interface SlickArrowProps {
    currentSlide?: number;
    slideCount?: number;
    [x: string]: any
}

export const SlickArrowLeft = (props: SlickArrowProps) => (
    <button
        {...props}
        className={
            "slick-prev slick-arrow" +
            (props.currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={props.currentSlide === 0 ? true : false}
        type="button"
    >
        <i className="fas fa-arrow-left"></i>
    </button>
);

export const SlickArrowRight = (props: SlickArrowProps) => (
    <button
        {...props}
        className={
            "slick-next slick-arrow" +
            (props.currentSlide === props.slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={props.currentSlide === props.slideCount - 1 ? true : false}
        type="button"
    >
        <i className="fas fa-arrow-right"></i>
    </button>
);