import React, {PropsWithChildren, ReactElement, useState} from 'react';
import {graphql, Link, useStaticQuery} from "gatsby";
import Slider from "react-slick";
import {SlickArrowLeft, SlickArrowRight} from "@components/common/HeroSlider/SlickArrows";
import {getArticleUrl, textPreview} from "@utils/utils";
import {getImage, ImageDataLike} from "gatsby-plugin-image";
import {convertToBgImage} from "gbimage-bridge";
import BackgroundImage from 'gatsby-background-image'

interface HeroSliderBackgroundProps {
   slider: any // Queries.STRAPI_ARTICLE
}

const HeroSliderBackground: React.FC<PropsWithChildren<HeroSliderBackgroundProps>> = (props: PropsWithChildren<HeroSliderBackgroundProps>): ReactElement => {
   const file: ImageDataLike = props.slider?.cover?.localFile as unknown as ImageDataLike || null;

   const image = getImage(file);
   const bgImage = convertToBgImage(image)

   return <div>
      <BackgroundImage
          Tag="section"
          {...bgImage}
          preserveStackingContext
      >
         {props.children}
      </BackgroundImage>
   </div>
}


const HeroSlider = () => {

   const slides = useStaticQuery(graphql`
      query {
         allStrapiArticle(
            limit: 3
            sort: {order: DESC, fields: publishedAt}
            filter: {showOnSlider: {eq: true}}
         ) {
            nodes {
               id
               slug
               strapi_id
               title
               description
               cover {
                  mime
                  localFile {
                     url
                     childImageSharp {
                        gatsbyImageData(aspectRatio: 1.5)
                     }
                  }
               }
            }
         }
      }
   `);

   const [sliderData] = useState<any>(slides.allStrapiArticle.nodes);  // Queries.STRAPI_ARTICLE[]

   const settings = {
      autoplay: false,
      autoplaySpeed: 10000,
      dots: false,
      fade: true,
      arrows: true,
      prevArrow: <SlickArrowLeft />,
      nextArrow: <SlickArrowRight />,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               infinite: true,
            }
         },
         {
            breakpoint: 991,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false
            }
         },
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false
            }
         }
      ]
   };

   return (
      sliderData?.length > 0 &&
      <>
         <section className="hero-area">
            <div className="hero-slider">
               <Slider className='slider-active' {...settings}>
                  {
                     sliderData.map((slider, index) => {
                        return (
                           <HeroSliderBackground slider={slider} key={index}>
                              <div className="single-slider h4slider-bg pos-rel d-flex align-items-center ps-55">
                                 <div className="container-fluid">
                                    <div className="row">
                                       <div className="col-xl-8 col-lg-12 col-md-10">
                                          <div className="hero-text">
                                             <div className="hero-slider-caption h4hero-content mb-35">
                                                <h1 className="white-color">{slider.title}</h1>
                                                <p className="white-color">{textPreview(slider.description, 30)}</p>
                                             </div>
                                             <div className="hero-slider-btn h4hero-btn">
                                                <Link to={ getArticleUrl(slider) } className="primary_btn btn-icon ml-0"><span>+</span>czytaj więcej</Link>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </HeroSliderBackground>
                        )
                     })
                  }
               </Slider>

            </div>
         </section>
      </>
   );
};

export default HeroSlider;