import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import Layout from "@components/layout";
import HeroSlider from "@components/common/HeroSlider/HeroSlider";
import {LatestArticles} from "@components/common/LatestArticles/LatestArticles";
import {HomeServices} from "@components/home/HomeServices/HomeServices";
import {BlockPricingOffer} from "@components/home/BlockPricingOffer/BlockPricingOffer";
import {FAQSection} from "@components/faq/faq-section";
import {BlockMakeAppointment} from "@components/home/BlockPricingOffer/BlockMakeAppointment";

const IndexPage: React.FC<PageProps> = () => {

  return (
    <Layout>
      <HeroSlider />
      <HomeServices />
      <BlockPricingOffer />
      <BlockMakeAppointment ></BlockMakeAppointment>
      {/*<LatestArticles />*/}
      <FAQSection />
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Home - Fizjoproject Kiekrz</title>
