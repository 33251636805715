import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {BlockOferta} from "@components/home/HomeServices/BlockOferta";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

export const HomeServices: React.FC = () => {

    const data = useStaticQuery(query);

    return (
        <section className="servcies-area pt-90 pb-90">
             <div className={"container"}>
                <div className={"row"}>
                    <div className="col-xl-7 col-lg-8 col-md-12" >
                        <div className="section-title pos-rel mb-75" >
                            <div className="section-text pos-rel"
                                 data-aos="fade-right"
                                 data-aos-offset="300"
                                 data-aos-easing="ease-in-sine"
                                 data-aos-once="true"
                            ><h5>Oferta</h5><h1>Zapoznaj się z naszą ofertą.</h1></div>
                        </div>
                    </div>
                    <div className={"col-12 block-oferta-container"}>
                        {/*<ResponsiveMasonry*/}
                        {/*    columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}*/}
                        {/*>*/}
                        {/*    <Masonry>*/}
                                {
                                    data.allStrapiOferta.nodes.map((oferta, index) => (
                                        <BlockOferta oferta={oferta} key={index}/>
                                    ))
                                }
                            {/*</Masonry>*/}
                        {/*</ResponsiveMasonry>*/}
                    </div>
                    <div className={"col-12"}>
                        <div className="section-button text-center pt-40">
                            <a className="primary_btn btn-dark btn-icon ml-0" href="/oferta"><span>⇢</span>zobacz ofertę</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const query = graphql`
    {
        allStrapiOferta(
            sort: {order: ASC, fields: Order}
            filter: {ShowOnHomepage: {eq: true}}
        ) {
            nodes {
                Order
                id
                ShowOnHomepage
                OfertaData {
                    name
                    description
                    images {
                        mime
                        localFile {
                            url
                            childImageSharp {
                                gatsbyImageData(aspectRatio: 1.5)
                            }
                        }
                    }
                }
                ImageOnHomepage {
                    mime
                    localFile {
                        url
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
                strapi_id
            }
        }
    }
`