import React from "react";
import {graphql, PageProps, useStaticQuery} from "gatsby";
import Slider from "react-slick";
import DoctorCardSlider from "@components/common/Doctor/doctor-card-slider";

export const BlockMakeAppointment: React.FC<PageProps> = (params: any) => {

    const settings = {
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        dots: true,
        lazyLoad: true,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                }
            }
        ],
        customPaging: i => (
            <div className={"custom-dots-slick"} />
        )
    };

    const employers = useStaticQuery(graphql`
        query {
            allStrapiEmployer(sort: {order: ASC, fields: order}) {
                nodes {
                    ...DoctorCard
                }
            }
        }
    `)

    return (
        <section data-section={'appointment'}>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12 section-title pos-rel mt-75"}>
                        <h1>Umów wizytę</h1>
                    </div>
                </div>
                <div className={"row mb-75"}>
                    <Slider {...settings}>
                        {employers.allStrapiEmployer.nodes.map(_ => (
                            <DoctorCardSlider employer={_} key={_.name} />
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
}