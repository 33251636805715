import moment from "moment";

export const textPreview = (text: string, length: number = 10): string => {
    const result = text.split(" ");
    if (result.length <= length) {
        return text;
    }
    else {
        return result.slice(0, length).join(" ") + "...";
    }
}

export const getArticleUrl = (article: Queries.STRAPI_ARTICLE): string => {
    return `/articles/${article.strapi_id}/${article.slug}`;
}

export const dateFormat = (date: string): string => {
    return moment(date).format('YYYY-MM-DD');
}